<template>
   <v-container 
      style="max-width:1600px;height:100%;background-size:cover;background-color:#00000033;"
      class="pa-0">

      <v-row style="height:100%;" class="ma-0 pa-0">
         <v-col 
            class="ma-0 pa-0 pl-6 py-6" 
            align="center" justify="center"
            style="height:100%;font-size:18px;font-weight:500;color:white;"
            >
            Cho phép notification để nhận những cảnh báo quan trọng!
         </v-col>
      </v-row>
   </v-container>
</template>

<script>
export default {
   methods: {

   },
   data () {
      return {
         records: [],
         temp: null,
         humidity: null,
         airCond1: null,
         airCond2: null
      }
   },
   sockets: {
      reportLastValue: function (payload) {
         if(payload && payload.devCode == this.$root.devCode){
            this.records = payload.content

            console.log(this.records)

            let recTemp = this.records.find(r => r.reportName == 'temp')
            if(recTemp) {
               this.temp = {}
               this.temp.value = Math.round(recTemp.value*10)/10
               this.temp.qos = recTemp.qos
               this.temp.date = new Date(recTemp.recordDate)
            }

            let recRH = this.records.find(r => r.reportName == 'humidity')
            if(recRH) {
               this.humidity = {}
               this.humidity.value = Math.round(recRH.value*10)/10
               this.humidity.qos = recRH.qos
               this.humidity.date = new Date(recRH.recordDate)
            }

            let recAirCond1 = this.records.find(r => r.reportName == 'airCon1')
            if(recAirCond1) {
               this.airCond1 = {}
               this.airCond1.value = recAirCond1.value
               this.airCond1.qos = recAirCond1.qos
               this.airCond1.date = new Date(recAirCond1.recordDate)
            }

            let recAirCond2 = this.records.find(r => r.reportName == 'airCon2')
            if(recAirCond2) {
               this.airCond2 = {}
               this.airCond2.value = recAirCond2.value
               this.airCond2.qos = recAirCond2.qos
               this.airCond2.date = new Date(recAirCond2.recordDate)
            }
            this.$forceUpdate()
         }

      }
   }
}
</script>

<style>
   .div-aircond {
      position:absolute;
      left:18px;
      top:28px;
      border-radius:8px;
      width:340px;
      background-color:#00000033;
      font-family: monospace;
      color:#69F0AE;
      font-size:36px;
      font-weight:700;
      text-align:left;
   }

   .div-temp {
      position:absolute;
      left:18px;
      bottom:28px;
      border-radius:8px;
      width:300px;
      background-color:#00000033;
      font-family: monospace;
      color:#69F0AE;
      font-size:76px;
      line-height: 1.1;
      font-weight:700;
      text-align:left;
      padding-left: 30px;
   }

   @media only screen and (max-width: 720px) {
      .div-aircond {
         position:absolute;
         left:8px;
         top:18px;
         border-radius:8px;
         width:280px;
         background-color:#00000033;
         font-family: monospace;
         color:#69F0AE;
         font-size:26px;
         font-weight:700;
         text-align:left;
      }

      .div-temp {
         position:absolute;
         left:8px;
         bottom:74px;
         border-radius:8px;
         width:210px;
         background-color:#00000033;
         font-family: monospace;
         color:#69F0AE;
         font-size:46px;
         font-weight:700;
         line-height: 1.1;
         text-align:left;
         padding-left: 20px;
      }
   }
</style>