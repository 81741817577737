<template>
  <v-container fluid class="pa-0"
    style="height:100%;background-image: url('/imgs/background_login.jpg');background-size:cover;">
      <v-row class="pa-0"
        justify="center" align="center" style="height:100%;">
          <home/>
      </v-row>
   </v-container>
</template>
<script>
// @ is an alias to /src
import Home from '@/components/HomeView.vue'

export default {
  name: 'HomePage',
  components: {
    Home
  },
  mounted() {
      this.$root.curTitle = "Nhà Nam"
  },
}
</script>
